import React from 'react'
import Router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
const App: React.FC = () => {
  return (
    <>
      <Router />
    </>
  )
}

export default App
