/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: `${process.env.REACT_APP_AWS_REGION}`,
  aws_cognito_identity_pool_id: `${process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID}`,
  aws_cognito_region: `${process.env.REACT_APP_AWS_REGION}`,
  aws_user_pools_id: `${process.env.REACT_APP_AWS_USER_POOL_ID}`,
  aws_user_pools_web_client_id: `${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}`,
  oauth: {
    domain: `${process.env.REACT_APP_OAUTH_DOMAIN}`,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${process.env.REACT_APP_POST_LOGIN_URL}`,
    redirectSignOut: `${process.env.REACT_APP_LOGOUT_URL}`,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
}

export default awsmobile
